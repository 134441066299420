<template>
  <Section class="no-padding-bottom">
    <Container>
      <h2 class="no-margin">Informations générales</h2>
    </Container>
  </Section>
  <Section>
    <Container>
      <div class="grid margin-bottom">
        <div class="tmp-col">
          <Input id="nom" label="Nom de l'essai" required />
        </div>
        <div class="tmp-col">
          <Input id="numero" label="Numero de l'essai" />
        </div>
      </div>
      <div class="grid margin-bottom">
        <div class="tmp-col">
          <SelectExtended id="technicien" required label="Technicien responsable" optionKey="id"
            optionValue="nom_complet" :apiParams="{
              sort: `prenom.ASC,nom.ASC`,
              filters: `entite.id:in(${$store.state.auth.user.data.entite.id})`
            }" apiEndpoint="utilisateur" />
        </div>
      </div>
      <template v-if="$route.name.includes('Edit')">
        <KeyValue label="Titre du protocole">
          <router-link :to="{ name: 'protocol', params: { id: essai?.protocole?.id }, query: { from: $route.fullPath } }">
            {{ essai?.protocole?.titre }}
          </router-link>
        </KeyValue>
        <KeyValue label="Année du protocole">
          {{ essai?.protocole?.annee_recolte.valeur }}
        </KeyValue>
      </template>
      <template v-else>
        <div class="grid margin-bottom">
          <div class="tmp-col">
            <SelectExtended id="year" label="Année du protocole" required optionKey="id" optionValue="valeur"
              :search="false" :items="$store.state.app.annee" v-model="year" />
          </div>
        </div>
        <div class="grid margin-bottom">
          <div class="tmp-col">
            <template v-if="displayProto">
              <SelectExtended v-if="reloaded" id="protocole" required label="Protocole" optionKey="id"
                optionValue="titre" :items="protocoles" big />
            </template>

            <template v-if="protocoles.length == 0">
              <p>Aucun protocole validé pour l'année {{ year.value }}.</p>
            </template>
          </div>
        </div>
      </template>
    </Container>
  </Section>
  <Section class="bg-gray-lightestest section--border-top">
    <Container>
      <h2 class="section-title">Parcelle</h2>
      <div class="grid margin-bottom">
        <div class="tmp-col">
          <SelectExtended label="Choisir la parcelle" id="parcelle" optionKey="id" optionValue="designation"
            :apiParams="parcelleParams" apiEndpoint="parcelle" />
        </div>
        <div class="tmp-col">
          <Checkbox id="plate_forme" label="Filtrer les parcelles" v-model="plateForme" :items="[
            {
              label: 'Afficher uniquement les plateformes d\'essais',
              value: 'true',
            },
          ]" />
        </div>
      </div>
    </Container>
  </Section>
  <Section class="section--border-top">
    <Container>
      <h2 class="section-title">Dimension des microparcelles</h2>

      <h3>Surface d'implantation</h3>
      <div class="grid margin-bottom">
        <div class="tmp-col">
          <Input id="surface_implantation_longueur" label="Longueur" input-after="m" />
        </div>
        <div class="tmp-col">
          <Input id="surface_implantation_largeur" label="Largeur" input-after="m" />
        </div>
      </div>

      <h3>Surface d’application</h3>
      <div class="grid margin-bottom">
        <div class="tmp-col">
          <Input id="surface_application_longueur" label="Longueur" input-after="m" />
        </div>
        <div class="tmp-col">
          <Input id="surface_application_largeur" label="Largeur" input-after="m" />
        </div>
      </div>

      <h3>Surface récoltée</h3>
      <div class="grid margin-bottom">
        <div class="tmp-col">
          <Input id="surface_recoltee_longueur" label="Longueur" input-after="m" />
        </div>
        <div class="tmp-col">
          <Input id="surface_recoltee_largeur" label="Largeur" input-after="m" />
        </div>
      </div>
    </Container>
  </Section>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Section from '@/components/layout/Section.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'generalInformationAddEditSubview',
  components: {
    Container,
    Section,
    Input,
    SelectExtended,
    Checkbox,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      nom: this.yup.string().required(),
      numero: this.yup.string().nullable(),
      protocole: this.yup.object().required(),
      technicien: this.yup.object().required(),
      parcelle: this.yup.object().nullable(),
      surface_implantation_largeur: this.yup.number().nullable(),
      surface_implantation_longueur: this.yup.number().nullable(),
      surface_application_largeur: this.yup.number().nullable(),
      surface_application_longueur: this.yup.number().nullable(),
      surface_recoltee_largeur: this.yup.number().nullable(),
      surface_recoltee_longueur: this.yup.number().nullable(),
    })

    // init du form
    const { errors, isSubmitting, setFieldValue } = this.formService.initFrom(validationSchema)

    // gestion du onSubmit
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    const filterSeasonObj = this.protocolService.filterByAgriculturalSeason('id', true)

    return {
      onSubmit,
      errors,
      isSubmitting,
      setFieldValue,
      year: filterSeasonObj.years.shift(),
      protocoles: [],
      method: 'post',
      endPoint: null,
      essai: {},
      filterSeasonObj: {},
      plateForme: null,
      reloaded: true,
      displayProto: true,
      parcelleParams: {
        sort: 'designation.ASC',
      },
    }
  },
  emits: ['set-data', 'set-step', 'set-route', 'update:modelValue'],
  watch: {
    async year() {
      this.reloaded = false
      await this.loadProtocol()
    },
    plateForme() {
      if (this.plateForme) {
        this.parcelleParams = {
          sort: 'designation.ASC',
          filters: 'plateforme:ist()',
        }
      } else {
        this.parcelleParams = {
          sort: 'designation.ASC',
        }
      }
    },
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      if (this.modelValue) {
        this.onSubmit()
      }
      this.$emit('update:modelValue', false)
    },
  },
  mounted() {
    this.$emit('set-step', 0)
    this.endPoint = 'essai'

    if (this.$route.params.id) {
      this.method = 'put'
      this.endPoint += `/${this.$route.params.id}`
      this.loadData()
    } else {
      this.setDefaultValues()
    }
  },
  methods: {
    async setDefaultValues() {
      this.emitter.emit('open-loader')
      const defaultValues = {}
      defaultValues.surface_implantation_largeur = JSON.parse(this.$store.getters['app/parameters']([
        'ESSAI_SURFACEIMPLANTATION_LARGEUR',
      ]).at(0).valeur)
      defaultValues.surface_implantation_longueur = JSON.parse(this.$store.getters['app/parameters']([
        'ESSAI_SURFACEIMPLANTATION_LONGUEUR',
      ]).at(0).valeur)
      defaultValues.surface_application_largeur = JSON.parse(this.$store.getters['app/parameters']([
        'ESSAI_SURFACEAPPLICATION_LARGEUR',
      ]).at(0).valeur)
      defaultValues.surface_application_longueur = JSON.parse(this.$store.getters['app/parameters']([
        'ESSAI_SURFACEAPPLICATION_LONGUEUR',
      ]).at(0).valeur)
      defaultValues.surface_recoltee_largeur = JSON.parse(this.$store.getters['app/parameters']([
        'ESSAI_SURFACERECOLTEE_LARGEUR',
      ]).at(0).valeur)
      defaultValues.surface_recoltee_longueur = JSON.parse(this.$store.getters['app/parameters']([
        'ESSAI_SURFACERECOLTEE_LONGUEUR',
      ]).at(0).valeur)

      const schema = this.formService.populateShema(defaultValues)

      await this.loadProtocol()

      this.formService.setFormValues(schema)

      this.emitter.emit('close-loader')
    },
    handleValues(values) {
      this.emitter.emit('open-loader')

      // eslint-disable-next-line no-param-reassign
      values.protocole_id = values.protocole.key

      if (values?.parcelle?.key) {
        // eslint-disable-next-line no-param-reassign
        values.parcelle_id = values.parcelle.key
      } else {
        // eslint-disable-next-line no-param-reassign
        values.parcelle_id = null
        // eslint-disable-next-line no-param-reassign
        values.parcelle_fiche_id = null
      }

      // eslint-disable-next-line no-param-reassign
      values.technicien_id = values.technicien.key

      if (this.plateForme) {
        // eslint-disable-next-line no-param-reassign
        values.plate_forme = this.plateForme
      }

      this.fetchService[this.method](this.endPoint, values).then(
        (reponse) => {
          this.emitter.emit('close-loader')
          if (this.$route.name.includes('Edit')) {
            this.emitter.emit('alert', {
              type: 'success',
              content: "L'essai a bien été mis à jour.",
            })
          } else if (values?.parcelle?.key) {
            const params = { id: reponse.data.id }
            this.$router.push({ name: 'essaiParcelleAdd', params })
          } else {
            const params = { id: reponse.data.id }
            this.$router.push({ name: 'essaiParcelleAdd', params })
          }
        },
        (error) => {
          this.emitter.emit('close-loader')
          this.formService.handleApiError(error)
        },
      )
    },
    async loadProtocol() {
      const response = await this.fetchService.get('protocole', { limit: 0, filters: `statut.uid:in(VALIDE,ENCOURS)|n|annee.id:in(${this.year.key})` })
      this.protocoles = response.data

      if (this.protocoles.length === 0) {
        this.displayProto = false
      } else {
        this.displayProto = true
      }

      this.reloaded = true
      return null
    },
    loadData() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`essai/${this.$route.params.id}`).then(
        (response) => {
          this.essai = response.data
          this.$emit('set-data', this.essai)

          // Populate schema with data
          const schema = this.formService.populateShema(this.essai)

          this.plateForme = !!this.essai.plate_forme

          // Set some specific data from user
          schema.protocole = {
            key: this.essai.protocole.id,
            value: this.essai.protocole.titre,
          }

          if (this.essai.parcelle?.id) {
            schema.parcelle = {
              key: this.essai.parcelle.id,
              value: this.essai.parcelle.designation,
            }
          }

          schema.technicien = {
            key: this.essai.technicien.id,
            value: this.essai.technicien.nom_complet,
          }

          // set form values
          this.formService.setFormValues(schema)
          this.emitter.emit('close-loader')
        },
        () => {
          this.emitter.emit('close-loader')
        },
      )
    },
  },
}
</script>
